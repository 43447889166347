import React from "react";

import Heading from "$ui/heading";

import AdminLayout from "$components/layout/admin";
import Account from "$components/account";
import EventsPanel from "$components/events/eventsPanel";

const AccountPage = () => {
  return (
    <AdminLayout title="Admin Account">
      <Account />

      {/* <section className='bg-white py-10 px-4'>
        <Heading variant='tertiary-left'>Upcoming events</Heading>

        <EventsPanel />
      </section> */}
    </AdminLayout>
  );
};

export default AccountPage;
